<template>
	<div class="m_common_content">
		<div class="m_common_top">
			<div class="m_checkdata_content">
				<!--<el-select v-model="selectValue" placeholder="请选择商品类型" clearable>
					<el-option label="全部" value="全部"> </el-option>
				</el-select>-->
				<el-input v-model="inputValue" placeholder="请输入商品名称" clearable></el-input>
				<el-button @click="search" class="m_btn_search">搜索</el-button>
			</div>
		</div>
		<div class="m_common_middle">
			<div class="m_table_content">
				<el-table :data="tableData" stripe>
					<el-table-column label="序号" type="index" width="60" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="goodsName" label="商品名称" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="goodsName" label="商品图片" show-overflow-tooltip>
						<template slot-scope="scope">
							<img :src="fileUrl + scope.row.goodsImg" alt="" style="width: 150px; padding: 5px" />
						</template>
					</el-table-column>
					<el-table-column prop="goodsPrice" label="商品原价" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="partnerName" label="区域合伙人" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="localPrice" label="区域零售价" show-overflow-tooltip>
					</el-table-column>
					<el-table-column label="操作" width="60px">
						<template slot-scope="scope">
							<i class="el-icon-edit-outline m_edit" @click="showUpdate(scope.row)" title="编辑"></i>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="m_common_bottom">
			<m-pagination @pageChange="pageChange" :total="total"></m-pagination>
		</div>
		<el-dialog :close-on-click-modal="false" :title="dialogTitle" :visible.sync="dialogVisible" :modal="false" v-el-drag-dialog width="600px">
			<div class="m_dialog_top" :style="dialogTopStyle">
				<el-form ref="form" :model="form" label-width="auto" :rules="rules" style="padding-right: 10px">
					<el-form-item label="区域价格" prop="localPrice">
						<el-input v-model="form.localPrice"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div class="m_dialog_bottom">
				<el-button type="primary" size="mini" class="m_btn_save" @click="save">保存</el-button>
				<el-button @click="dialogVisible = false" size="mini" class="m_btn_cancel">取消</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	import elDragDialog from "@/directive/el-drag-dialog";
	import mPagination from "@/components/pagination/index.vue";
	import { PAGE_SIZE } from "@/utils/config";
	import { getLocalprices, setLocalprices } from "@/api/regionPrice";
	import { XHR_URL } from "@/utils/config";
	import regulars from "@/utils/regular";
	export default {
		directives: {
			elDragDialog
		},
		data() {
			return {
				total: 0,
				currentPage: 1,
				inputValue: null,
				selectValue: "",
				tableData: [{
					status: 1,
				}, ],
				map: null,
				dialogTitle: "添加",
				dialogVisible: false,
				rules: {
					localPrice: [{
							required: true,
							message: "区域售价不能为空",
							trigger: "blur"
						},
						{
							pattern: regulars.priceNumber,
							message: "价格格式错误",
							trigger: "blur",
						},
					],
				},
				form: {
					partnerId: "",
					goodsId: "",
					localPrice: "",
				},
			};
		},
		props: {},
		computed: {
			pageSize() {
				return PAGE_SIZE;
			},
			fileUrl() {
				return XHR_URL;
			},
			dialogTopStyle() {
				return {
					maxHeight: document.body.offsetHeight * 0.6 + "px",
					height:'100px'
				};
			},
		},
		components: {
			mPagination,
		},
		created() {
			this.select();
		},
		mounted() {},
		methods: {
			pageChange(page) {
				this.currentPage = page;
				this.select();
			},
			search() {
				this.select();
			},
			showUpdate(data) {
				this.initForm();
				this.dialogTitle = "修改";
				this.form = {
					partnerId: data.partnerId,
					goodsId: data.goodsId,
					localPrice: data.localPrice,
				};
				this.dialogVisible = true;
			},
			initForm() {
				if(this.$refs.form) {
					this.$refs.form.resetFields();
				}
				this.form = {
					partnerId: "",
					goodsId: "",
					localPrice: "",
				};
			},
			save() {
				this.$refs.form.validate((valid) => {
					if(valid) {
						this.update();
					} else {
						return false;
					}
				});
			},
			async select() {
				const res = await getLocalprices({
					pageNo: this.currentPage,
					pageSize: this.pageSize,
					gdname:this.inputValue
				});
				const {
					pageCount,
					results
				} = res;
				this.tableData = results;
				this.total = pageCount.allCount;
			},
			async update() {
				const res = await setLocalprices(this.form);
				const {
					pageCount,
					results
				} = res;
				this.$message({
					message: "修改成功",
					type: "success",
				});
				this.select();
				this.dialogVisible = false;
			},
		},
		beforeDestroy() {},
		destroyed() {},
	};
</script>
<style lang="less" scoped>
	@import "../../assets/css/common/common.less";
</style>
<style lang="less">
	@import "../../assets/css/checkdata/checkdata.less";
</style>